<template>
  <div class="topLinks">
    <a
      class="link"
      target="_blank"
      href="https://wholefoodearth.com/"
    >
      Whole Food Earth®
    </a>
    <router-link
      class="link"
      to="/o/rawgorilla"
    >
      RAWGORILLA
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'TopLinks',
  mounted () {
  }
}
</script>
<style scoped lang="stylus">
.topLinks {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #EAEAEA

  .link {
    max-height: 20px;
    overflow: hidden
    padding: 5px 10px;
    color: #000;
    border-left: 1px solid #EAEAEA
  }
}
</style>
